@import "./variables/variables";

.slider__content-img {
  display: flex;
  align-items: flex-end;

  @include phones {
    height: 250px !important;
  }
}

.slick-slider {
  .custom-dots {
    display: flex !important;
    justify-content: center;
    transform: translateY(25px);

    li {
      background: $background-color;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      display: block;
      margin-right: 6px;
      position: relative;
      transition: all linear 0.3s;

      &:last-child {
        margin-right: 0;
      }
    }

    button {
      display: none;
    }

    .slick-active {
      background: $background-btn;
      width: 18px;
      border-radius: 50px;
    }

    .dot {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .slick-slide {
    & > div {
      height: 100%;
    }

    & * {
      outline: none;
    }
  }
}
