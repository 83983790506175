@import "../mixins/mixins";

// Colors
$white: #fff;
$black: #000;
$primary-text: #000;
$secondary-text: #999999;
$background: #f8f8f8;
$background-btn: #408f4e;
$background-btn-hover: #66b674;
$background-color: #d1f2df;
$link-hover-color: #009245;
$close-btn: #a9a9a9;

// Fonts
$regular-font: "Poppins", sans-serif;
$system-fonts-list: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol";

// Font Weight
$fw-base: 400;

// Line Height
$lh-base: 16px;

// Font size
$fs-base: 13px !default;

// Paddings and margins
$gap1: 10px;
$gap2: 20px;
$gap3: 30px;
$gap4: 40px;
